import { combineReducers } from 'redux';
import userReducers from '../components/auth/reducers';
import accessReducers from '../components/accessManagement/reducers';
import commonReducers from '../components/common/reducers';
import teacherReducers from '../components/teacherManagement/reducers';
import schoolManagementReducers from '../components/schoolManagement/reducers';
import userManagementReducers from '../components/userManagement/reducers';
import generalSettingsReducers from '../components/generalSettings/reducers';
import studentManagementReducers from '../components/studentManagement/reducers';
import resourceManagementReducers from '../components/resourceManagement/reducers';
import addResourceReducers from '../components/resourceManagement/addResource/redux/reducers';
import assessmentReducers from '../components/assessment/reducers';
import assignClassReducers from '../components/assignClass/reducers';
import holidayManagementReducers from '../components/leaveManagement/reducers';
import coursePlannerReducers from '../components/coursePlanner/reducers';
import timetableReducers from '../components/timetable/reducers';
import communicateReducers from '../components/communication/reducers';
import zoomReducers from '../components/interactive/zoom/reducers';
import interactiveReducers from '../components/interactive/reducers';
import practiceReducers from '../components/practice/reducers';
import workSheetReducers from '../components/workSheet/reducers';
import dashboardReducers from '../components/dashboard/reducers';
import lessonPlannerReducer from '../components/lessonPlanner/reducer';
import attendanceReducer from '../components/attendance/reducer';

const rootReducer = combineReducers({
    userReducers,
    commonReducers,
    schoolManagementReducers,
    accessReducers,
    teacherReducers,
    userManagementReducers,
    generalSettingsReducers,
    studentManagementReducers,
    resourceManagementReducers,
    addResourceReducers,
    assessmentReducers,
    assignClassReducers,
    holidayManagementReducers,
    coursePlannerReducers,
    timetableReducers,
    communicateReducers,
    zoomReducers,
    interactiveReducers,
    practiceReducers,
    workSheetReducers,
    dashboardReducers,
    lessonPlannerReducer,
    attendanceReducer,
});

export default rootReducer;
