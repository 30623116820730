import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import redirectFn, { transformMenus } from '../../utils/enums/sideBarMenus';
import { getAllowedPermissions } from './actions';
import * as actionType from './constants';
import SchoolHeader from './SchoolHeader';
import { FALSY_VALUES } from '../../utils/validations';

const SideBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [current, setCurrent] = useState('');
    const sideBarResponse = useSelector((state) => state.commonReducers.sideBarResponse);
    const { isExpanded } = useSelector((state) => state.commonReducers.toggleSidebar);
    let sideBarMenus =
        sideBarResponse?.data?.data?.rolePermissions || sideBarResponse?.data?.data?.allowed_permissions || [];
    sideBarMenus = [...sideBarMenus].filter((menu) => menu.is_active);
    sideBarMenus = transformMenus(sideBarMenus);

    const { userDetails } = JSON.parse(sessionStorage.getItem('login-Data')).data;
    const currentTab = sessionStorage.getItem('currentTab') || '';
    const attrCheck = () => {
        if (document.body.getAttribute('data-leftbar-compact-mode') === 'condensed') {
            dispatch({ type: actionType.TOGGLE_SIDEBAR_OFF });
        } else {
            dispatch({ type: actionType.TOGGLE_SIDEBAR_ON });
        }
        if (window.innerWidth <= 768) {
            document.body.classList.remove('menu-open');
            dispatch({ type: actionType.TOGGLE_SIDEBAR_OFF });
        }
        if (window.innerWidth <= 990) {
            const navElem = document.getElementById(`navbarDropdownUl_${currentTab}`);
            if (navElem) {
                navElem.classList.remove('show');
            }
        }
        // if (document.body.classList.contains('menu-open')) document.body.classList.remove('menu-open');
        // if (document.body.getAttribute('data-leftbar-compact-mode') !== 'condensed')
        //     document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
        // const navElem = document.getElementById(`navbarDropdownUl_${currentTab}`)
        // if(navElem){
        //     document.getElementById(`navbarDropdownUl_${currentTab}}`).classList.remove('show')
        // }

        // if (window.innerWidth <= 990) {
        //     dispatch({ type: actionType.TOGGLE_SIDEBAR_OFF });
        //     if (document.body.classList.contains('menu-open')) document.body.classList.remove('menu-open');
        // } else {
        //     dispatch({ type: actionType.TOGGLE_SIDEBAR_ON });
        // }
    };

    useEffect(() => {
        if (userDetails?.role?.profile_name === 'ADMIN') {
            document.title = 'Smart Kindergarten';
        } else {
            const schoolName = userDetails?.school?.school_name || 'Smart Kindergarten';
            document.title = schoolName;
        }
        dispatch(getAllowedPermissions());
        attrCheck();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', attrCheck);
        return () => window.removeEventListener('resize', attrCheck);
    }, []);

    useEffect(() => {
        if (currentTab) {
            setCurrent(currentTab);
        }
        attrCheck();
    }, [location]);

    const menuClickHandler = (name) => {
        sessionStorage.setItem('currentTab', name);
        setCurrent((prev) => {
            // if (prev === name) {
            //     return '';
            // }
            return name;
        });
    };

    const NormalLI = ({ menu }) => {
        return (
            <li key={menu.display_name} onClick={() => menuClickHandler(menu.display_name)}>
                {!isExpanded && <ReactTooltip id="toolTipicon" backgroundColor="#0477BF" />}
                <NavLink
                    to={redirectFn(menu.permission_name, menu.sub_permission)?.redirectUrl}
                    // className={(navData) => (navData.isActive ? 'active' : '')}
                    className={
                        location.pathname.split('/')[1] === menu.permission_name.split('_')[0].toLowerCase()
                            ? 'active'
                            : ''
                    }
                >
                    <span className="menu-icon" data-tip={menu.display_name} data-for="toolTipicon" data-place="right">
                        <img src={redirectFn(menu.permission_name)?.imageUrl} alt="" />
                    </span>
                    {menu.display_name}
                </NavLink>
            </li>
        );
    };

    const DropDownLI = ({ menu }) => {
        return (
            <li
                className="nav-item dropdown"
                key={menu.display_name}
                onClick={() => menuClickHandler(menu.display_name)}
            >
                <a
                    className={`nav-link dropdown-toggle ${
                        menu.display_name === current || menu.display_name === currentTab ? 'show' : ''
                    }`}
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <span className="menu-icon" data-tip={menu.display_name} data-for="toolTipicon" data-place="right">
                        <img src={menu.imageUrl} alt="" />
                    </span>
                    {menu.display_name}
                </a>
                <ul
                    className={`dropdown-menu ${menu.display_name === current ? 'show' : ''}`}
                    id={`navbarDropdownUl_${menu.display_name}`}
                >
                    {(menu?.sub_menus || []).map((subMenus) => (
                        <li key={subMenus.display_name}>
                            <NavLink
                                to={redirectFn(subMenus.permission_name, subMenus.sub_permission)?.redirectUrl}
                                // className={(navData) => (navData.isActive ? 'active' : '')}
                                className={
                                    location.pathname.split('/')[1] ===
                                        subMenus.permission_name.split('_')[0].toLowerCase() ||
                                    location.pathname.split('/')[1] ===
                                        `${subMenus.permission_name.split('_')[0].toLowerCase()}s`
                                        ? 'active dropdown-item'
                                        : 'dropdown-item'
                                }
                            >
                                <span className="menu-icon">
                                    <img src={redirectFn(subMenus.permission_name)?.imageUrl} alt="" />
                                </span>
                                {subMenus.display_name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </li>
        );
    };

    return (
        <div className="navbar-main">
            <Link to="/" className="logo">
                <span className="logo-span">
                    {userDetails?.role?.profile_name === 'ADMIN' ? (
                        <img
                            src={
                                userDetails?.role?.profile_name === 'ADMIN'
                                    ? '/images/logo.png'
                                    : '/images/brand-large.png'
                            }
                            width="180"
                            alt=""
                            className="desktop-view"
                        />
                    ) : (
                        <span className="text-logo desktop-view">
                            {userDetails?.school?.school_name ? (
                                <div className="school_header_container d-flex  align-items-center bg-transparent">
                                    <i className="bi bi-building" />
                                    <span className="school_header_color">{userDetails?.school?.school_name}</span>
                                </div>
                            ) : (
                                'Smart Kindergarten'
                            )}
                        </span>
                    )}
                    <img src="/images/logo-icon.png" alt="" className="compact-view" />
                </span>
            </Link>
            <div className="navbar-menu">
                <ul className="list-unstyled">
                    {sideBarMenus.map((menu) => {
                        if(FALSY_VALUES.includes(menu)) return;
                        return !menu.is_dropable ? (
                            <NormalLI menu={menu} key={menu.display_name} />
                        ) : (
                            <DropDownLI menu={menu} key={menu.display_name} />
                        );
                    })}
                </ul>
                <div className="logo-bottom">
                    <img src="/images/logo.png" width="180" alt="" />
                </div>
            </div>
        </div>
    );
};

export default SideBar;
